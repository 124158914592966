import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "src/config/colors";

interface HowMakeOrderItemProps {
  title: string;
  number: string;
}

const HowMakeOrderItem: React.FC<HowMakeOrderItemProps> = ({
  title,
  number,
}) => {
  return (
    <Box
      sx={{
        minWidth: "146px",
        minHeight: { xs: "88px", tb: "136px" },
        maxHeight: { xs: "98px" },
        padding: "24px 26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
        backgroundColor: "#F6F7F8",
        position: "relative",
      }}
    >
      <Typography
        component="span"
        sx={{
          fontSize: { xs: "14px", tb: "16px" },
          color: colors.black2,
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          position: "absolute",
          top: -2,
          right: -2,
          width: "28px",
          height: "28px",
          borderRadius: "50%",
          backgroundColor: colors.yellow,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "2px solid #fff",
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: { xs: "10px", tb: "12px" },
            color: colors.black2,
          }}
        >
          {number}
        </Typography>
      </Box>
    </Box>
  );
};

export default HowMakeOrderItem;
