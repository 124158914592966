import React from "react";
import Typography from "@mui/material/Typography";
import { colors } from "src/config/colors";
import Container from "src/components/common/Layout/Container";
import styled from "./styled";
import { PopularBrandsSchema } from "src/models/economy/types";
import { useModalContext } from "src/components/common/Layout/context/ModalContext";
import { ModalType } from "src/utils/hooks/state/useModal";
import DefaultButton from "src/components/common/Buttons/DefaultButton";

interface SearchProductProps {
  title?: string;
  isMobile?: boolean;
  popular_brands?: PopularBrandsSchema[];
}

const SearchProduct: React.FC<SearchProductProps> = ({ title }) => {
  const { openModal } = useModalContext();

  return (
    <Container bgColor={colors.white} paddingBottom={6} sx={styled.root}>
      {title && (
        <Typography
          variant="h1"
          component="h1"
          color={colors.black2}
          mb={2}
          textAlign="center"
        >
          {title}
        </Typography>
      )}
      <Typography
        variant="h3"
        component="h3"
        fontSize={{ xs: 14, tb: 16 }}
        color={colors.black2}
        textAlign="center"
        mb={4}
      >
        Хватит переплачивать за лекарства! <br />
        Сравните цены, забронируйте в аптеке и сэкономьте до 50%
      </Typography>
      <DefaultButton
        onClick={() => openModal(ModalType.SEARCH)}
        title="Найти лучшую цену"
        sx={{
          width: "197px",
          height: "56px",
        }}
        typographySx={{
          fontSize: "16px",
          color: colors.black2,
        }}
      />
    </Container>
  );
};

export default SearchProduct;
