import React from "react";
import { SeoSchema } from "src/models/meta/getMetaData";

import Head from "next/head";
import { Box, Breadcrumbs, Link } from "@mui/material";
import Typography from "@mui/material/Typography";

interface BreadcrumbsComponentProps {
  breadcrumbs: SeoSchema["breadcrumbs"];
  snippetBackground: SeoSchema["snippetBackground"];
  maxWidth?: "sm" | "lg" | "md";
}

const BreadcrumbsComponent: React.FC<BreadcrumbsComponentProps> = ({
  breadcrumbs,
  snippetBackground,
  maxWidth = "lg",
}) => (
  <Box bgcolor={snippetBackground}>
    <Breadcrumbs
      aria-label="breadcrumb"
      maxItems={4}
      sx={{
        fontSize: "13px",
        padding: "16px 8px",
        lineHeight: "20px",
        margin: "0 auto",
        maxWidth: maxWidth,
        backgroundColor: snippetBackground,
      }}
    >
      {breadcrumbs &&
        breadcrumbs.map((crumb) =>
          crumb.slug ? (
            <Link
              underline="hover"
              color="inherit"
              href={crumb.slug}
              key={crumb.slug}
            >
              {crumb.name}
            </Link>
          ) : (
            <Typography
              color="text.primary"
              lineHeight="20px"
              fontSize={13}
              key={crumb.slug}
            >
              {crumb.name}
            </Typography>
          )
        )}
    </Breadcrumbs>
  </Box>
);

const MetaContainer: React.FC<SeoSchema> = ({
  children,
  description,
  breadcrumbs,
  canonical,
  disallowRobots,
  snippetBrand,
  snippetBreadcrumbs,
  snippetContacts,
  title,
  snippetBackground,
  maxWidth,
}) => {
  return (
    <>
      <Head>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {canonical && <link rel="canonical" href={canonical} />}
        {disallowRobots && <meta name="robots" content="noindex, nofollow" />}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              url: "https://cenyvaptekah.ru/",
              logo: "https://cenyvaptekah.ru/assets/icons/logo.png",
            }),
          }}
        />
        {snippetBrand && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(snippetBrand),
            }}
          />
        )}
        {snippetBreadcrumbs && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(snippetBreadcrumbs),
            }}
          />
        )}
        {snippetContacts && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(snippetContacts),
            }}
          />
        )}
      </Head>
      {breadcrumbs && breadcrumbs[0] && (
        <BreadcrumbsComponent
          maxWidth={maxWidth}
          snippetBackground={snippetBackground}
          breadcrumbs={breadcrumbs}
        />
      )}
      {children}
    </>
  );
};

export default MetaContainer;
