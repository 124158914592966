import apteka_36 from "src/assets/png/36.png";
import aloe_apteka from "src/assets/png/aloe_apteka.png";
import asna from "src/assets/png/asna.png";
import byd_zdorov from "src/assets/png/byd_zdorov.png";
import doktor from "src/assets/png/doktor.png";
import gorzdrav from "src/assets/png/gorzdrav.png";
import magnit from "src/assets/png/magnit.png";
import nadejda_farm from "src/assets/png/nadejda_farm.png";
import ozerki from "src/assets/png/ozerki.png";
import planeta_zdorovya from "src/assets/png/planeta_zdorovya.png";
import pro_apteka from "src/assets/png/pro_apteka.png";
import puls from "src/assets/png/puls.png";
import rigla from "src/assets/png/rigla.png";
import sklad from "src/assets/png/sklad.png";
import vita from "src/assets/png/vita.png";
import zdravsiti from "src/assets/png/zdravsiti.png";

export const partnersContent = [
  {
    icon: apteka_36,
  },
  {
    icon: aloe_apteka,
  },
  {
    icon: asna,
  },
  {
    icon: byd_zdorov,
  },
  {
    icon: doktor,
  },
  {
    icon: gorzdrav,
  },
  {
    icon: magnit,
  },
  {
    icon: nadejda_farm,
  },
  {
    icon: ozerki,
  },
  {
    icon: planeta_zdorovya,
  },
  {
    icon: pro_apteka,
  },
  {
    icon: puls,
  },
  {
    icon: rigla,
  },
  {
    icon: sklad,
  },
  {
    icon: vita,
  },
  {
    icon: zdravsiti,
  },
];
