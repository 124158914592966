import React from "react";
import TabletBanner from "src/assets/banners/tablet_banner.png";
import MobileBanner from "src/assets/banners/mobile_banner.png";
import DesktopBanner from "src/assets/banners/desktop_banner.png";
import { Box, Typography } from "@mui/material";
import { colors } from "src/config/colors";
import Link from "src/components/common/Links/Link";

const BannerSection: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: { xs: "12px", md: "0px" },
        paddingRight: { xs: "12px", md: "0px" },
      }}
    >
      <Box
        sx={{
          width: { xs: "328px", tb: "640px", lg: "1140px" },
          height: { xs: "280px", tb: "336px", lg: "400px" },
          backgroundImage: {
            xs: `url(${MobileBanner.src})`,
            tb: `url(${TabletBanner.src})`,
            lg: `url(${DesktopBanner.src})`,
          },
          borderRadius: "24px",
          backgroundRepeat: "no-repeat",
          paddingLeft: { xs: "16px", tb: "48px", lg: "78px" },
          paddingTop: { xs: "35px", tb: "53px", lg: "61px" },
        }}
      >
        <Typography
          component="h4"
          sx={{
            maxWidth: "400px",
            fontSize: { xs: "24px", tb: "32px", lg: "48px" },
            color: colors.white,
            fontWeight: 600,
            lineHeight: { xs: "28px", tb: "38px", lg: "57px" },
            mb: 2,
          }}
        >
          Ваше мнение <br /> важно для нас!
        </Typography>
        <Typography
          sx={{
            maxWidth: { xs: "180px", tb: "320px" },
            fontSize: { xs: "12px", tb: "16px" },
            color: colors.white,
            lineHeight: { xs: "18px", tb: "22px" },
            mb: 3,
          }}
        >
          Помогите нам стать лучше. Пройдите короткий опрос и поделитесь своим
          мнением. Это займет не более 5 минут.
        </Typography>
        <Link
          href="https://docs.google.com/forms/d/e/1FAIpQLSfNqzzui-r5oe88-edw0bZS4dVE9e-i6_myNS0nJH7coJC1Tg/viewform?usp=sharing"
          target="_blank"
          width="100px"
          sx={{
            borderRadius: "8px",
            padding: "14px 24px",
            backgroundColor: colors.yellow,
            fontSize: { xs: "12px", tb: "16px" },
            color: colors.black2,
            textDecoration: "none",
            ":hover": {
              opacity: ".8",
            },
          }}
        >
          Пройти опрос
        </Link>
      </Box>
    </Box>
  );
};

export default BannerSection;
