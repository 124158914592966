import React from "react";
import { Box, Grid } from "@mui/material";
import { GridSize } from "@mui/material/Grid/Grid";
import { ArticleListIcon } from "src/assets/icons";
import { colors } from "src/config/colors";
import MainArticleItem from "src/components/common/ProductArticles/components/MainArticleItem";

export interface ArticleCardProp {
  gridLg?: GridSize;
  imgSrc?: string | null;
  title: string;
  author: string | null;
  createDate: string;
  href: string;
  date: string;
}

export const ArticleIcon: React.FC<{ size?: string }> = ({ size = "74px" }) => (
  <Box
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    mb={1}
    borderRadius="5px"
    bgcolor={colors.fadedYellow}
  >
    <ArticleListIcon viewBox="0 0 74 65" width={size} height={size} />
  </Box>
);

const ArticleCard: React.FC<ArticleCardProp> = ({
  imgSrc,
  title,
  author,
  createDate,
  gridLg,
  href,
  date,
}) => {
  return (
    <Grid sx={{ minHeight: "100%" }} item xs={12} md={6} lg={gridLg || 4}>
      <MainArticleItem
        isFullWidth
        title={title}
        date={date}
        slug={href}
        image={imgSrc}
      />
    </Grid>
  );
};

export default ArticleCard;
