import Box from "@mui/material/Box";
import React from "react";
import { colors } from "src/config/colors";

interface DangerouslyHtmlProps {
  innerHtml?: string;
  className?: string | undefined;
}

const DangerouslyHtml: React.FC<DangerouslyHtmlProps> = ({
  innerHtml,
  className,
}) => (
  <Box
    className={className}
    sx={{ color: colors.blue }}
    dangerouslySetInnerHTML={{ __html: innerHtml || "" }}
  />
);

export default DangerouslyHtml;
