import React from "react";
import Slider, { ResponsiveObject } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StyledSx } from "src/types/StyledSx";
import Box from "@mui/material/Box";
import MainArticleItem from "src/components/common/ProductArticles/components/MainArticleItem";
import { CommonArticlesSchema } from "src/models/articles/getCommonArticles";

const styled: StyledSx = {
  slide: {
    padding: "5px",
    width: "100%",
  },
};

interface ArticlesSliderProps {
  commonArticles: CommonArticlesSchema[];
}

const ArticlesSlider: React.FC<ArticlesSliderProps> = ({ commonArticles }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    arrows: false,
    dotsClass: "slick-dots",
    responsive: [
      {
        breakpoint: 5000,
        settings: "unslick" as ResponsiveObject["settings"],
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <Slider className="article-slider" {...settings}>
      {commonArticles?.map((value, index) => (
        <Box key={value.date + index} sx={styled.slide}>
          <MainArticleItem
            title={value.name}
            date={value.date}
            slug={value.slug}
            image={value.image}
          />
        </Box>
      ))}
    </Slider>
  );
};

export default ArticlesSlider;
