import React from "react";
import { Button } from "@mui/material";
import { colors } from "src/config/colors";
import Typography from "@mui/material/Typography";
import { useRouter } from "next/router";
import {
  AllSystemCSSProperties,
  ResponsiveStyleValue,
  SxProps,
  Theme,
} from "@mui/system";

interface DefaultButtonProps {
  onClick?: () => void;
  href?: string;
  title?: string;
  sx?: SxProps<Theme>;
  typographySx?: SxProps<Theme>;
  fontSize?: ResponsiveStyleValue<AllSystemCSSProperties["fontSize"]>;
  type?: "button" | "submit" | "reset";
}

const DefaultButton: React.FC<DefaultButtonProps> = ({
  onClick,
  href = "/",
  title,
  sx,
  fontSize = "10px",
  typographySx,
  type,
}) => {
  const router = useRouter();

  return (
    <Button
      type={type}
      variant="contained"
      sx={{
        height: "23px",
        textTransform: "none",
        padding: "4px 7px",
        ...sx,
      }}
      disableElevation
      color="secondary"
      onClick={() => {
        onClick ? onClick() : router.push(href);
      }}
    >
      <Typography
        component="span"
        fontSize={fontSize}
        fontWeight={400}
        lineHeight="10px"
        color={colors.blue}
        sx={typographySx}
      >
        {title}
      </Typography>
    </Button>
  );
};

export default DefaultButton;
