import React from "react";
import SearchProduct from "src/components/General/SearchProduct/SearchProduct";
import HowMakeOrder from "src/components/General/HowMakeOrder/HowMakeOrder";
import { SeoSchema } from "src/models/meta/getMetaData";
import useMediaQuery from "@mui/material/useMediaQuery";
import CitySeoInfo from "src/components/General/CitySeoInfo/CitySeoInfo";
import PartnersSection from "src/components/General/PartnersSection/PartnersSection";
import Articles from "src/components/common/ProductArticles/Articles";
import BannerSection from "src/components/General/BannerSection/BannerSection";
import { GeneralPageProps } from "pages";
/*import TgSection from "src/components/General/TgSection/TgSection";*/

const General: React.FC<SeoSchema & GeneralPageProps> = (props) => {
  const isMobile = useMediaQuery("(max-width:600px)", {
    defaultMatches: true,
  });

  return (
    <>
      <SearchProduct
        popular_brands={props.aboutInfo?.popular_brands}
        title={props.h1}
        isMobile={isMobile}
      />
      <BannerSection />
      {/*<TgSection />*/}
      <HowMakeOrder />
      <Articles
        specialArticleData={props.specialArticleData}
        commonArticleData={props.commonArticleData}
      />
      <PartnersSection />
      <CitySeoInfo
        title={props.aboutInfo?.page_title}
        textHtml={props.aboutInfo?.page_text_html}
      />
    </>
  );
};
export default General;
