import React from "react";
import { Typography } from "@mui/material";
import DangerouslyHtml from "src/components/common/DangerouslyHtml/DangerouslyHtml";
import Container from "src/components/common/Layout/Container";

interface CitySeoInfoProps {
  title?: string;
  textHtml?: string;
}

const CitySeoInfo: React.FC<CitySeoInfoProps> = ({ title, textHtml }) => {
  if (!title) return null;

  return (
    <Container isWithPaper paddingBottom={2}>
      <Typography variant="h2" component="h2" mb={4} sx={{ fontWeight: "400" }}>
        {title}
      </Typography>
      <DangerouslyHtml innerHtml={textHtml} />
    </Container>
  );
};

export default CitySeoInfo;
