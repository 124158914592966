import React from "react";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { partnersContent } from "src/components/General/PartnersSection/partnersContent";
import Image from "next/image";
import Container from "src/components/common/Layout/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/system";
import { colors } from "src/config/colors";

const PartnersSection: React.FC = () => {
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg")
  );

  const settings = {
    dots: isLargeScreen,
    infinite: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 2,
    dotsClass: "slick-dots",
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 2,
          variableWidth: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 0,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          adaptiveHeight: true,
        },
      },
    ],
  };

  return (
    <Container
      isWithPaper
      wrapperSx={{
        boxShadow: "none",
      }}
      sx={{
        paddingBottom: { xs: "44px", lg: "64px" },
      }}
    >
      <Typography
        component="h4"
        sx={{
          textAlign: "center",
          color: colors.black,
          fontSize: "32px",
          fontWeight: 500,
          mb: 3,
        }}
      >
        Наши партнёры
      </Typography>
      <Slider {...settings}>
        {partnersContent.map((value, index) => (
          <Box
            sx={{
              padding: "0px 7px",
            }}
            key={index}
          >
            <Box
              key={index}
              sx={{
                cursor: "pointer",
                width: "275px",
                height: "100px",
                borderRadius: "8px",
                backgroundColor: "#F6F7F8",
                padding: "24px 32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ":hover": {
                  ":hover": {
                    boxShadow: "0px 3px 16px rgba(12, 21, 57, 0.10)",
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  layout="intrinsic"
                  src={value.icon}
                  alt="partners icon"
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default PartnersSection;
