import { colors } from "src/config/colors";
import { StyledSx } from "src/types/StyledSx";

const SearchProductStyled: StyledSx = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: { xs: 4, tb: 10 },
  },
  searchHistory: {
    marginRight: 1.25,
  },
  historyItem: {
    padding: "14px 17px",
    background: "rgba(255, 255, 255, 0.2)",
    borderRadius: "5px",
    margin: "0 8px 8px 0",
  },
  underLine: {
    color: colors.blue,
    fontWeight: 500,
    textDecoration: "underline",
  },
  pharmacyItem: {
    height: 40,
    width: 40,
    marginRight: 1,
    "&:last-child": {
      margin: 0,
    },
  },
};

export default SearchProductStyled;
