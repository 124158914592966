import { Grid, Typography } from "@mui/material";
import React from "react";
import Container from "src/components/common/Layout/Container";
import { colors } from "src/config/colors";
import HowMakeOrderItem from "src/components/General/HowMakeOrder/components/HowMakeOrderItem";

const HowMakeOrder: React.FC = () => {
  return (
    <Container
      isWithPaper
      paddingBottom={7}
      paddingTop={7}
      wrapperSx={{
        boxShadow: "none",
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        component="h4"
        sx={{
          textAlign: "center",
          color: colors.black,
          fontSize: { xs: "20px", tb: " 32px" },
          fontWeight: 500,
          mb: 6,
        }}
      >
        Как оформить заказ
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, tb: 3 }}>
        <Grid item xs={6} lg={3}>
          <HowMakeOrderItem number="01" title="Найдите товар" />
        </Grid>
        <Grid item xs={6} lg={3}>
          <HowMakeOrderItem number="02" title="Добавьте его в корзину" />
        </Grid>
        <Grid item xs={6} lg={3}>
          <HowMakeOrderItem number="03" title="Забронируйте в аптеке" />
        </Grid>
        <Grid item xs={6} lg={3}>
          <HowMakeOrderItem number="04" title="Заберите заказ в аптеке" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HowMakeOrder;
