import React from "react";
import Container from "src/components/common/Layout/Container";
import { colors } from "src/config/colors";
import { Box, ButtonBase, Typography } from "@mui/material";
import DefaultButton from "src/components/common/Buttons/DefaultButton";
import ArticlesSlider from "src/components/common/ProductArticles/components/ArticlesSlider";
import paths from "src/router/paths";
import { createUrl } from "src/utils/helpers/url/createUrl";
import { MainSpecialArticleSchema } from "src/models/articles/getSpecialArticle";
import { CommonArticlesSchema } from "src/models/articles/getCommonArticles";

interface ArticleSectionProps {
  specialArticleData?: MainSpecialArticleSchema;
  commonArticleData?: CommonArticlesSchema[];
}

const Articles: React.FC<ArticleSectionProps> = ({
  specialArticleData,
  commonArticleData,
}) => {
  if (!commonArticleData || !specialArticleData) return null;

  return (
    <Container
      isWithPaper
      paddingBottom={7.5}
      wrapperSx={{
        boxShadow: "none",
      }}
    >
      <Typography
        component="h4"
        sx={{
          textAlign: "center",
          color: colors.black,
          fontSize: "32px",
          fontWeight: 500,
          mb: 3,
        }}
      >
        Уголок здоровья
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          height: "100%",
        }}
      >
        <Box
          sx={{
            mr: 1,
          }}
        >
          <ArticlesSlider commonArticles={commonArticleData} />
        </Box>
        <ButtonBase
          href={"/" + paths.BLOG}
          sx={{
            marginBottom: 5,
            display: { xs: "flex", lg: "none" },
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
            borderBottom: "1px solid #F7E64D",
            fontSize: { xs: "14px", tb: "16px" },
          }}
        >
          Перейти в блог
        </ButtonBase>
        <Box
          sx={{
            width: { xs: "100%", lg: "382px" },
            backgroundColor: "#F6F7F8",
            borderRadius: "8px",
            padding: { xs: "24px 16px", lg: "120px 24px" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", tb: "32px" },
              color: colors.black2,
              fontWeight: 500,
              lineHeight: "normal",
              mb: 3,
            }}
          >
            {specialArticleData.name}
          </Typography>
          <Typography
            sx={{
              color: colors.black2,
              fontSize: { xs: "14px", tb: "16px" },
              lineHeight: "22px",
              mb: 4,
            }}
          >
            Ценываптеках.рф - удобный сервис, который помогает вам сэкономить
            время и деньги при покупке в аптеках. Экономьте до 50% с помощью
            нашего сервиса.
            <br />
            <br />
            Вся необходимая информация собрана в одном месте. Вы можете сравнить
            предложения аптек, забронировать нужный товар на нашем сайте и
            забрать его в удобной для вас аптеке.
          </Typography>
          <DefaultButton
            href={createUrl([
              paths.BLOG,
              paths.ARTICLE,
              specialArticleData.slug,
            ])}
            title="Подробнее"
            sx={{
              width: "131px",
              height: "48px",
              padding: "24px, 16px",
            }}
            typographySx={{
              fontSize: { xs: "14px", tb: "16px" },
              color: colors.black2,
            }}
          />
        </Box>
      </Box>
      <ButtonBase
        href={"/" + paths.BLOG}
        sx={{
          mt: 4,
          display: { xs: "none", lg: "flex" },
          width: "fit-content",
          alignItems: "center",
          borderRadius: "4px",
          marginLeft: "auto",
          marginRight: "auto",
          borderBottom: "1px solid #F7E64D",
          fontSize: { xs: "14px", tb: "16px" },
        }}
      >
        Перейти в блог
      </ButtonBase>
    </Container>
  );
};

export default Articles;
