import React from "react";
import { Box, Typography } from "@mui/material";
import Image from "next/image";
import { colors } from "src/config/colors";
import { createUrl } from "src/utils/helpers/url/createUrl";
import paths from "src/router/paths";
import { useRouter } from "next/router";
import { ArticleIcon } from "src/components/common/ArticleCard/ArticleCard";
import Link from "src/components/common/Links/Link";

interface MainArticleItemProps {
  title: string;
  date: string;
  slug: string;
  image?: string | null;
  isFullWidth?: boolean;
}

export const MainArticleItem: React.FC<MainArticleItemProps> = ({
  title,
  date,
  slug,
  image,
  isFullWidth,
}) => {
  const router = useRouter();

  return (
    <Box
      onClick={() => router.push(createUrl([paths.BLOG, paths.ARTICLE, slug]))}
      sx={{
        backgroundColor: colors.white,
        maxWidth: isFullWidth ? "100%" : { xs: "296px", md: "374px" },
        minWidth: isFullWidth ? "100%" : { xs: "296px", md: "374px" },
        minHeight: "324px",
        height: "100%",
        borderRadius: "8px",
        border: "1px solid var(--light-gray, #EBEBEB)",
        ":hover": {
          boxShadow: "0px 3px 16px 0px rgba(12, 21, 57, 0.10);",
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          aspectRatio: "16 / 9",
          borderRadius: "8px 8px 0px 0px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {image ? (
          <Image src={image} alt="article image" layout="fill" />
        ) : (
          <ArticleIcon />
        )}
      </Box>
      <Box
        sx={{
          padding: "16px",
        }}
      >
        <Link
          href={createUrl([paths.BLOG, paths.ARTICLE, slug])}
          sx={{
            textDecoration: "none",
            fontSize: { xs: "14px", tb: "16px" },
            fontWeight: 500,
            lineHeight: "20px",
            color: colors.black2,
            mb: 4,
            ":hover": {
              color: "#3C56CB",
            },
          }}
        >
          {title}
        </Link>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#909090",
              fontSize: "12px",
              lineHeight: "normal",
            }}
          >
            {date}
          </Typography>
          {/*<Box>Счетчик просмотров статьи</Box>*/}
        </Box>
      </Box>
    </Box>
  );
};

export default MainArticleItem;
